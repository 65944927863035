////////////////////////////////////////////////////////////////////////////////////////////////
// GALLERY PREVIEWS ////////////////////////////////////////////////////////////////////////////

// тренажерный зал
import gallery_preview_slide_01 from './images/gallery/previews/slide_01.jpg'
import gallery_preview_slide_02 from './images/gallery/previews/slide_02.jpg'
import gallery_preview_slide_03 from './images/gallery/previews/slide_03.jpg'
import gallery_preview_slide_04 from './images/gallery/previews/slide_04.jpg'
import gallery_preview_slide_05 from './images/gallery/previews/slide_05.jpg'
import gallery_preview_slide_06 from './images/gallery/previews/slide_06.jpg'
import gallery_preview_slide_07 from './images/gallery/previews/slide_07.jpg'
import gallery_preview_slide_08 from './images/gallery/previews/slide_08.jpg'
import gallery_preview_slide_09 from './images/gallery/previews/slide_09.jpg'
import gallery_preview_slide_10 from './images/gallery/previews/slide_10.jpg'
import gallery_preview_slide_11 from './images/gallery/previews/slide_11.jpg'
import gallery_preview_slide_12 from './images/gallery/previews/slide_12.jpg'
import gallery_preview_slide_13 from './images/gallery/previews/slide_13.jpg'

// групповые занятия
import gallery_preview_slide_14 from './images/gallery/previews/slide_14.jpg'
import gallery_preview_slide_15 from './images/gallery/previews/slide_15.jpg'
import gallery_preview_slide_16 from './images/gallery/previews/slide_16.jpg'

// спа
import gallery_preview_slide_17 from './images/gallery/previews/slide_17.jpg'
import gallery_preview_slide_18 from './images/gallery/previews/slide_18.jpg'
import gallery_preview_slide_19 from './images/gallery/previews/slide_19.jpg'


////////////////////////////////////////////////////////////////////////////////////////////////
// GALLERY SLIDES //////////////////////////////////////////////////////////////////////////////

// тренажёрный зал
import gallery_slide_01 from './images/gallery/slides/slide_01.jpg'
import gallery_slide_02 from './images/gallery/slides/slide_02.jpg'
import gallery_slide_03 from './images/gallery/slides/slide_03.jpg'
import gallery_slide_04 from './images/gallery/slides/slide_04.jpg'
import gallery_slide_05 from './images/gallery/slides/slide_05.jpg'
import gallery_slide_06 from './images/gallery/slides/slide_06.jpg'
import gallery_slide_07 from './images/gallery/slides/slide_07.jpg'
import gallery_slide_08 from './images/gallery/slides/slide_08.jpg'
import gallery_slide_09 from './images/gallery/slides/slide_09.jpg'
import gallery_slide_10 from './images/gallery/slides/slide_10.jpg'
import gallery_slide_11 from './images/gallery/slides/slide_11.jpg'
import gallery_slide_12 from './images/gallery/slides/slide_12.jpg'
import gallery_slide_13 from './images/gallery/slides/slide_13.jpg'

// групповые занятия
import gallery_slide_14 from './images/gallery/slides/slide_14.jpg'
import gallery_slide_15 from './images/gallery/slides/slide_15.jpg'
import gallery_slide_16 from './images/gallery/slides/slide_16.jpg'

// спа
import gallery_slide_17 from './images/gallery/slides/slide_17.jpg'
import gallery_slide_18 from './images/gallery/slides/slide_18.jpg'
import gallery_slide_19 from './images/gallery/slides/slide_19.jpg'


////////////////////////////////////////////////////////////////////////////////////////////////
// EXPORTS /////////////////////////////////////////////////////////////////////////////////////

export const club = {
    name: 'DDX Браво',
    tour_3d: 'https://my.matterport.com/show/?m=mBF2cevfSni',
    rating: '5,0',
    rating_number: 5.0,
    reviews: '219 отзывов',
    reviews_link: 'https://yandex.ru/maps/org/ddx_fitness/169710901298/reviews/?ll=37.759228%2C55.639034&z=16',
    address: 'г. Москва, район Братеево, ул. Борисовские пруды 26, корпус 2, ТЦ "БраVo!", 2 этаж',
    route: 'https://yandex.ru/maps/213/moscow/?indoorLevel=2&ll=37.759612%2C55.638732&mode=routes&rtext=~55.639234%2C37.759439&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D169710901298&utm_source=share&z=17.89',
    phone: '+7 (495) 255-50-00',
    phone_link: 'tel:74952555000',
    schedule: 'Ежедневно с 6 до 24',
    coordinates: [55.639234, 37.759440],
    clubs_total: 38,
    ym_code: 95203633,
    vk_code: 3401285,
    clubs_link_with_utms: 'https://ddxfitness.ru/clubs?utm_source=landing_brateevo-bravo&utm_medium=cpc&utm_campaign=performance',
}

export const HERO_SLIDES = [
    // '/images/hero/slide_01.webp',
    '/images/hero/slide_02.jpg',
    '/images/hero/slide_03.jpg',
    '/images/hero/slide_04.jpg',
    '/images/hero/slide_05.jpg'
]

export const ZONES_SLIDES = [
    { 
        name: 'Тренажёрный зал', 
        formattedName: 'Тренажёрный зал', 
        title: <div>ТРЕНАЖЁРНЫЙ <br />ЗАЛ</div>, 
        image: '/images/hero/slide_02.jpg',
        preview: '/images/hero/slide_02.jpg',
        list: ['829 кв. метров топового оборудования', '56 кардио тренажеров', '50 силовых тренажеров', 'Зона свободных весов']
    },
    {
        name: 'Зал групповых занятий',
        formattedName: <span>Зал&nbsp;групповых занятий</span>,
        title: <div>Зал&nbsp;групповых занятий</div>,
        image: '/images/hero/slide_03.jpg',
        preview: '/images/hero/slide_03.jpg',
        list: ['5 зон групповых программ', 'DDX Athletic', 'Cross Box', '30 min Legs & Hips', '30 min Fullbody']
    },
    { 
        name: 'СПА',
        formattedName: 'СПА',
        title: 'СПА',
        image: '/images/zones/slide_03.jpg',
        preview: '/images/zones/slide_03.jpg',
        list: ['62 кв. метра', 'Соляная комната', 'Хаммам', 'Сауна']
    },
]

export const PREVIEW_SLIDES = [
    { id: 'gallery_slide_01', image: gallery_preview_slide_01 },
    { id: 'gallery_slide_02', image: gallery_preview_slide_02 },
    { id: 'gallery_slide_03', image: gallery_preview_slide_03 },
    { id: 'gallery_slide_04', image: gallery_preview_slide_04 },
    { id: 'gallery_slide_05', image: gallery_preview_slide_05 },
    { id: 'gallery_slide_06', image: gallery_preview_slide_06 },
    { id: 'gallery_slide_07', image: gallery_preview_slide_07 },
    { id: 'gallery_slide_08', image: gallery_preview_slide_08 },
    { id: 'gallery_slide_09', image: gallery_preview_slide_09 },
    { id: 'gallery_slide_10', image: gallery_preview_slide_10 },
    { id: 'gallery_slide_11', image: gallery_preview_slide_11 },
    { id: 'gallery_slide_12', image: gallery_preview_slide_12 },
    { id: 'gallery_slide_13', image: gallery_preview_slide_13 },
    { id: 'gallery_slide_14', image: gallery_preview_slide_14 },
    { id: 'gallery_slide_15', image: gallery_preview_slide_15 },
    { id: 'gallery_slide_16', image: gallery_preview_slide_16 },
    { id: 'gallery_slide_17', image: gallery_preview_slide_17 },
    { id: 'gallery_slide_18', image: gallery_preview_slide_18 },
    { id: 'gallery_slide_19', image: gallery_preview_slide_19 },
]

export const GALLERY_SLIDES = [
    { id: 'gallery_slide_01', slide: gallery_slide_01, categories: ['тренажёрный зал', 'ddx athletics'] },
    { id: 'gallery_slide_02', slide: gallery_slide_02, categories: ['тренажёрный зал', 'ddx athletics'] },

    { id: 'gallery_slide_03', slide: gallery_slide_03, categories: ['тренажёрный зал', 'зона свободных весов'] },
    { id: 'gallery_slide_04', slide: gallery_slide_04, categories: ['тренажёрный зал', 'зона свободных весов'] },
    { id: 'gallery_slide_05', slide: gallery_slide_05, categories: ['тренажёрный зал', 'зона свободных весов'] },
    
    { id: 'gallery_slide_06', slide: gallery_slide_06, categories: ['тренажёрный зал', 'блочные тренажеры'] },
    { id: 'gallery_slide_07', slide: gallery_slide_07, categories: ['тренажёрный зал', 'блочные тренажеры'] },
    { id: 'gallery_slide_08', slide: gallery_slide_08, categories: ['тренажёрный зал', 'блочные тренажеры'] },
    { id: 'gallery_slide_09', slide: gallery_slide_09, categories: ['тренажёрный зал', 'блочные тренажеры'] },
    
    { id: 'gallery_slide_10', slide: gallery_slide_10, categories: ['тренажёрный зал', 'кардио зона'] },
    { id: 'gallery_slide_11', slide: gallery_slide_11, categories: ['тренажёрный зал', 'кардио зона'] },
    { id: 'gallery_slide_12', slide: gallery_slide_12, categories: ['тренажёрный зал', 'кардио зона'] },
    { id: 'gallery_slide_13', slide: gallery_slide_13, categories: ['тренажёрный зал', 'кардио зона'] },
    
    { id: 'gallery_slide_14', slide: gallery_slide_14, categories: ['зал групповых занятий'] },
    { id: 'gallery_slide_15', slide: gallery_slide_15, categories: ['зал групповых занятий'] },
    { id: 'gallery_slide_16', slide: gallery_slide_16, categories: ['зал групповых занятий'] },

    { id: 'gallery_slide_17', slide: gallery_slide_17, categories: ['спа', 'соляная комната'] },
    { id: 'gallery_slide_18', slide: gallery_slide_18, categories: ['спа', 'финская сауна'] },
    { id: 'gallery_slide_19', slide: gallery_slide_19, categories: ['спа', 'хаммам'] },
]

export const CATEGORIES = {
    'тренажёрный зал': ['Все зоны', 'DDX Athletics', 'Зона свободных весов', 'Блочные тренажеры', 'Кардио зона'],
    'спа': ['Все зоны', 'Финская сауна', 'Хаммам', 'Соляная комната'],
    'галерея': ['Все зоны','тренажёрный зал', 'Зал групповых занятий', 'СПА']
}